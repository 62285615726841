import React from 'react';
import config from '../../../../config';
import NumberFilter from '@inovua/reactdatagrid-community/NumberFilter';
import DateFilter from '@inovua/reactdatagrid-community/DateFilter';
import Header from '../../../components/Header/Header';
import Toolbar from '../../../components/Toolbar/Toolbar';
import Table from '../../../components/Table/Table';
import NavBar from '../../../components/NavBar/NavBar';
import BoolFilter from '@inovua/reactdatagrid-community/BoolFilter';
import { IconButton, Switch, Tooltip } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { FaAddressCard } from 'react-icons/fa';
import AccountCard from '../../../components/AccountCard';

const Account = () => {
  const urls = {
    list: config.ACCOUNT,
    create: config.ACCOUNT,
    update: config.ACCOUNT_WITH_ID,
    delete: config.ACCOUNT_WITH_ID
  };
  const resource = 'ACCOUNT';
  const { t } = useTranslation();

  const columns = [
    {
      header: t('id'),
      name: 'accountId',
      type: 'number',
      filterEditor: NumberFilter
    },
    {
      header: t('email'),
      name: 'email',
      type: 'string',
      isEditable: true,
      defaultValue: null,
      defaultFlex: 1
    },
    {
      header: t('phone.number'),
      type: 'string',
      isEditable: true,
      defaultValue: null,
      required: true,
      name: 'phoneNumber'
    },
    {
      header: t('first.name'),
      type: 'string',
      isEditable: true,
      defaultValue: null,
      name: 'firstName'
    },
    {
      header: t('middle.name'),
      type: 'string',
      isEditable: true,
      defaultValue: null,
      name: 'middleName'
    },
    {
      header: t('last.name'),
      type: 'string',
      isEditable: true,
      defaultValue: null,
      name: 'lastName'
    },
    {
      header: t('nickname'),
      type: 'string',
      isEditable: true,
      defaultValue: null,
      name: 'nickname'
    },
    {
      header: t('date.of.birth'),
      name: 'dateOfBirth',
      type: 'date',
      format: 'date',
      isEditable: true,
      filterEditor: DateFilter,
      dateFormat: 'YYYY-MM-DDTHH:mm:ss'
    },
    {
      header: t('registration.method'),
      defaultValue: 'PHONE',
      isEditable: true,
      name: 'registrationMethod'
    },
    // {
    //   header: t('deviceTypeId'),
    //   defaultValue: -1,
    //   isEditable: true,
    //   name: 'deviceTypeId'
    // },
    // {
    //   header: t('deviceSubtypeId'),
    //   defaultValue: -1,
    //   isEditable: true,
    //   name: 'deviceSubtypeId'
    // },
    {
      header: t('registration.country'),
      name: 'registrationCountry',
      type: 'select',
      isEditable: true,
      defaultValue: null,
      options: {
        url: config.COUNTRY,
        name: 'name',
        uniqueId: 'code',
        requestField: 'registrationCountryCode'
      },
      render: ({ value }) => value && value.name,
      defaultFlex: 1,
      required: true,
      excludeInUpdate: true
    },
    {
      header: t('residence.country'),
      name: 'residenceCountry',
      type: 'select',
      isEditable: true,
      defaultValue: null,
      options: {
        url: config.COUNTRY,
        name: 'name',
        uniqueId: 'code',
        requestField: 'residenceCountryCode'
      },
      render: ({ value }) => value && value.name,
      defaultFlex: 1
    },
    {
      type: 'text',
      isEditable: false,
      defaultValue: 'PHONE',
      options: {
        name: 'name',
        uniqueId: 'registrationMethod',
        requestField: 'registrationMethod'
      }
    },
    {
      header: t('is.active'),
      name: 'isActive',
      type: 'boolean',
      isEditable: true,
      filterEditor: BoolFilter,
      render: ({ value }) => <Switch disabled checked={value} />
    },
    {
      header: t('date.added'),
      name: 'dateAdded',
      type: 'date',
      filterEditor: DateFilter,
      dateFormat: 'YYYY-MM-DDTHH:mm:ss'
    },
    {
      header: t('date.modified'),
      name: 'dateModified',
      type: 'date',
      filterEditor: DateFilter,
      dateFormat: 'YYYY-MM-DDTHH:mm:ss'
    }
  ];

  const accountCardGenerator = (
    selectedRow,
    openCard,
    handleClickOpenAccountCard,
    handleCloseAccountCard
  ) => {
    return {
      toolTip: (
        <Tooltip title={t('account.card')}>
          <IconButton onClick={handleClickOpenAccountCard}>
            <FaAddressCard fontSize={20} />
          </IconButton>
        </Tooltip>
      ),
      card: (
        <AccountCard
          iamResource={resource}
          account={selectedRow}
          open={openCard}
          handleClose={handleCloseAccountCard}
        />
      )
    };
  };

  return (
    <>
      <Header title={t('accounts')} />
      <Toolbar iamResource={resource} detailedCardGenerator={accountCardGenerator} />
      <Table
        iamResource={resource}
        uniqueId={'accountId'}
        tableId={'accountId'}
        columns={columns}
        urls={urls}
      />
      <NavBar />
    </>
  );
};

export default Account;
